import {
  Box,
  Text,
  Flex,
  keyframes,
} from '@chakra-ui/react';
import Link from 'next/link';
import SwiperCore, {
  Pagination,
  Navigation,
  Autoplay
} from 'swiper';
import {
  Swiper,
  SwiperSlide
} from 'swiper/react';
import { Image } from '@/components/Image';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import { BannersQuery } from '@/graphql/generated/graphql';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const swiperStyles = {
  '.swiper-button-prev': {
    color: 'brandSecondary.500',
  },
  '.swiper-button-prev:after': {
    textStyle: 'h1'
  },
  '.swiper-button-next': {
    color: 'brandSecondary.500',
  },
  '.swiper-button-next:after': {
    textStyle: 'h1'
  },
  '.swiper-pagination': {
    textAlign: 'right'
  },
  '.swiper-pagination-bullet': {
    bg: 'brandSecondary.500'
  },
};

type TopBannerSwiperProps = {
  banners: BannersQuery['banners'];
}

const dummyBanner: BannersQuery['banners'] = [
{
  id: 1,
  altText: 'reraku',
  spImage: '/images/course_category.jpeg',
  pcImage: '/images/dummy_banner.png',
  imageLink: '',
}];

export const TopBannerSwiper = ({
  banners
}: TopBannerSwiperProps) => {
  const displayBanners = banners.length > 0 ? banners : dummyBanner;

  return (
    <>
      <Flex
        overflow={'hidden'}
        sx={swiperStyles}
      >
        <Box
          bg={'brand.100'}
          px={'5%'}
          display={{ base: 'none', lg: 'inline-block' }}
        >
          <Box
            as={'h2'}
            mt={10}
            width={{ lg: 120, xl: '210px' }}
            height={{ lg: 120, xl: '210px' }}
          >
            <Box
              position={'relative'}
              mx={'auto'}
              width={{ lg: 110, xl: '210px' }}
              height={{ lg: 110, xl: '210px' }}
            >
              <Image
                src={'/images/top/top_page_title.png'}
                alt={'人の一生を健康に'}
                style={{ objectFit: 'contain' }}
                fill
              />
            </Box>
          </Box>
          <Box
            width={{ lg: 120, xl: '210px' }}
            mt={{ lg: 3, xl: 8 }}
          >
            <Text
              color={'deepGreen.500'}
              textAlign={'justify'}
              textStyle={{ lg: 'h7', xl: 'h6' }}
            >
              リラクグループでは、コミュニケーションとボディケアを通じて、活力ある毎日を送るためのサポートをしています。
            </Text>
          </Box>
          <Box
            px={{ lg: '20%', xl: '32%' }}
            pt={8}
            display={{ base: 'none', lg: 'inline-block' }}
            pb={{lg: '8px', xl: '0px'}}
            mb={'8px'}
          >
            <Box
              width={'80px'}
              height={'80px'}
            >
              <Box
                position={'relative'}
                mx={'auto'}
                w={'80px'}
                h={'80px'}
                animation={`${arrowMove} 01s ease-in-out infinite`}
              >
                <Image
                  src={'/images/top/pc/scroll.png'}
                  alt={'スクロール'}
                  style={{ objectFit: 'contain' }}
                  fill
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          navigation
          autoplay={displayBanners.length > 1 ? { delay: 7000 } : false}
          loop={true}
        >
          {displayBanners.map(({
            altText,
            spImage,
            pcImage,
            imageLink,
          }) =>
            <SwiperSlide
              key={altText}
            >
              <Link
                href={imageLink}
              >
                <Box
                  display={{ base: 'block', md: 'none' }}
                >
                  <Image
                    src={spImage}
                    alt={altText}
                    width={900}
                    height={900}
                  />
                </Box>
              </Link>
              <Link
                href={imageLink}
              >
                <Box
                  display={{ base: 'none', md: 'block' }}
                  height={'auto'}
                >
                  <Image
                    src={pcImage}
                    alt={altText}
                    width={1280}
                    height={720}
                  />
                </Box>
              </Link>
            </SwiperSlide>
          )}
        </Swiper>
      </Flex>
    </>
  );
};

const arrowMove = keyframes`
  0%{
    bottom:1%;
  }
  50%{
    bottom:10%;
  }
  100%{
    bottom:1%;
  }
`;
